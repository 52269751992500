import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faUsersCog, faWarehouse } from '@fortawesome/free-solid-svg-icons'
import { formatExpiryDate } from '../helpers/helpers'

class KeyVaultFacts extends React.Component {
  constructor(props) {
    super(props);

    this.generateReadableFilesize = this.generateReadableFilesize.bind(this);
  }

  generateReadableFilesize(sizeInBytes) {
    if (sizeInBytes === 'Calculating...') {
      return 'Calculating...';
    }

    if (isNaN(sizeInBytes)) {
      return 'Unknown';
    }

    // Average file size on storage is 1.75MB. Therefore, check for small files
    // first. Hopefully this will optimise things.
    if (sizeInBytes < 1024) {
      return `${sizeInBytes.toPrecision(5)} B`;
    }

    if (sizeInBytes < Math.pow(1024, 2)) {
      var sizeInKBs = sizeInBytes / 1024;
      return `${sizeInKBs.toPrecision(5)} KB`;
    }

    if (sizeInBytes < Math.pow(1024, 3)) {
      var sizeInMBs = sizeInBytes / (Math.pow(1024, 2));
      return `${sizeInMBs.toPrecision(5)} MB`;
    }

    if (sizeInBytes < Math.pow(1024, 4)) {
      var sizeInGBs = sizeInBytes / (Math.pow(1024, 3));
      return `${sizeInGBs.toPrecision(5)} GB`;
    }

    var sizeInTBs = sizeInBytes / (Math.pow(1024, 4));
    return `${sizeInTBs.toPrecision(5)} TB`;
  }

  render() {
    const { owners } = this.props;

    return(
      <React.Fragment>
        <div class='o-grid__row'>
          <div class='o-grid__box o-grid__box--third'>
            <div class='o-media__picture'>
              <FontAwesomeIcon size='7x' icon={faUsersCog} />
            </div>
            <div class='o-media__body'>
              <h3 class='c-key-fact__title'><strong>Owners</strong></h3>
              <div class='c-key-fact__content'>
                <strong>
                  <p>
                    <ul>
                    {
                      owners.map((owner, index) => (
                        <li>{owner}&nbsp;</li>
                      ))
                    }
                    </ul>
                  </p>
                </strong>
              </div>
            </div>
          </div>
          <div class='o-grid__box o-grid__box--third'>
            <div class='o-media__picture'>
              <FontAwesomeIcon size='7x' icon={faDatabase} />
            </div>
            <div class='o-media__body'>
              <h3 class='c-key-fact__title'><strong>Total size</strong></h3>
              <div class='c-key-fact__content'>
                <p><strong>{this.generateReadableFilesize(this.props.size)}</strong></p>
              </div>
            </div>
          </div>
          <div class='o-grid__box o-grid__box--third'>
            <div class='o-media__picture'>
              <FontAwesomeIcon size='7x' icon={faWarehouse} />
            </div>
            <div class='o-media__body'>
              <h3 class='c-key-fact__title'><strong>Being stored until</strong></h3>
              <div class='c-key-fact__content'><strong>{formatExpiryDate(this.props.expiryDate)}</strong></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default KeyVaultFacts
