import React from "react"
import { Auth } from "aws-amplify"

class UpdateArchiveOwners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonText: 'Update owners'
    };

    this.updateArchiveOwners = this.updateArchiveOwners.bind(this);
    this.updateParentState = this.updateParentState.bind(this);
  }

  updateParentState(response) {
    this.props.callback(response);

    this.setState({
      buttonText: 'Update owners'
    });
  }

  async updateArchiveOwners() {
    await this.setState({
      buttonText: 'Loading...'
    });

    let patchRequestBody = {
      owners: this.props.owners
    };

    Auth.currentSession()
      .then(async data => {
        const res = await fetch(`${process.env.GATSBY_API_URL}/vault/${this.props.archiveid}`,
        { method: 'PATCH',
          mode: 'cors',
          headers: new Headers({
            'Authorization': 'Bearer ' + data.idToken.jwtToken
          }),
          body: JSON.stringify(patchRequestBody) });

        this.updateParentState(res);
    })
    .catch(err => console.log(err));
  }

  render() {
    return(
      <React.Fragment>
        <input type="submit" class="c-btn c-btn--medium c-btn--success" value="Update vault owners" onClick={this.updateArchiveOwners} />
      </React.Fragment>);
  }
}

export default UpdateArchiveOwners
