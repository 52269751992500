import React from "react"
import Spinner from "./spinner"
import { Auth } from "aws-amplify"

class ArchiveFilesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      files: [],
      totalVaultSize: 0
    };

    this.updateParentState = this.updateParentState.bind(this);
    this.generateReadableFilesize = this.generateReadableFilesize.bind(this);
  }

  updateParentState() {
    this.props.callback(this.state.totalVaultSize);
  }

  async componentDidMount() {
    Auth.currentSession()
      .then(async data => {
        var res = await fetch(
          `${process.env.GATSBY_API_URL}/vault/${this.props.archiveid}/files`,
          {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
              'Authorization': 'Bearer ' + data.idToken.jwtToken
            })
          }
        );

        const result = await res.json();

        var totalVaultSize = 0;

        result.forEach((value) => {
          if (!isNaN(Number.parseFloat(value.sizeInBytes))) {
            totalVaultSize = totalVaultSize + Number.parseFloat(value.sizeInBytes);
          }
        });

        await this.setState({
          loaded: true,
          files: result,
          totalVaultSize: totalVaultSize
        });

        this.updateParentState();
      })
      .catch(err => console.log(err));
  }

  generateReadableFilesize(sizeInBytes) {
    if (isNaN(sizeInBytes)) {
      return 'Unknown';
    }

    // Average file size on storage is 1.75MB. Therefore, check for small files
    // first. Hopefully this will optimise things.
    if (sizeInBytes < Math.pow(1024, 2)) {
      var sizeInKBs = sizeInBytes / 1024;
      return `${sizeInKBs.toPrecision(5)} KB`;
    }

    if (sizeInBytes < Math.pow(1024, 3)) {
      var sizeInMBs = sizeInBytes / (Math.pow(1024, 2));
      return `${sizeInMBs.toPrecision(5)} MB`;
    }

    if (sizeInBytes < Math.pow(1024, 4)) {
      var sizeInGBs = sizeInBytes / (Math.pow(1024, 3));
      return `${sizeInGBs.toPrecision(5)} GB`;
    }

    var sizeInTBs = sizeInBytes / (Math.pow(1024, 4));
    return `${sizeInTBs.toPrecision(5)} TB`;
  }

  render() {
    const { loaded, files } = this.state;

    if (!loaded) {
      return <Spinner />;
    } else{
      var count = files.length;

      if (count === 0) {
        return <p>Archive currently empty.</p>;
      } else {
        return(
          <React.Fragment>
            <table>
              <thead>
              <tr>
          	  <th>Filename</th>
          	  <th>Filesize</th>
          	  <th>ETag</th>
          	</tr>
                </thead>
                <tbody>
          	{
          	  files.map((file) => (
          	    <tr key={file.id}>
          	      <td key="0">{file.filename}</td>
          	      <td key="1">{this.generateReadableFilesize(Number.parseFloat(file.sizeInBytes))}</td>
          	      <td key="2">{file.etag}</td>
          	    </tr>
          	  ))
          	}
                </tbody>
            </table>
          </React.Fragment>);
      }
    }
  }
}

export default ArchiveFilesTable
