import React from "react"
import Layout from "../components/layout"
import SpecificArchiveDetails from "../components/specificarchivedetails"

class Details extends React.Component {
  render() {
    // Renders an error page if you come to /details directly.
    if (this.props.location.state == null) {
      return(
        <React.Fragment>
          <Layout>
            <div class="c-alert c-alert--warning c-alert" role="alert">
              <div class="c-alert__content">
                <strong>You cannot reach this page directly; please visit <a href="https://vault.aws.york.ac.uk">https://vault.aws.york.ac.uk</a>.</strong>
              </div>
            </div>
          </Layout>
        </React.Fragment>);
    } else {
      return(
        <React.Fragment>
          <Layout>
            <SpecificArchiveDetails details={this.props.location.state.details} />
          </Layout>
        </React.Fragment>);
    }
  }
}

export default Details
