import React from 'react'
import AWS from 'aws-sdk'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import Tooltip from './tooltip'
import { Auth } from "aws-amplify"

@observer class Uploader extends React.Component {
  @observable buttonText = 'Upload selected files ';
  @observable spin = false;
  @observable disabled = true;
  @observable buttonIcon = faCloudUploadAlt;
  @observable uploadedFiles = [];
  @observable successfulUploadCounter = 0;
  @observable failedUploadCounter = 0;
  @observable failures = [];

  constructor(props) {
    super(props);

    this.state = {
      files: null,
      secrets: null
    }

    this.handleInputChange = this.handleInputChange.bind(this);
    this.uploadSelectedFiles = this.uploadSelectedFiles.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      files: event.target.files
    });
  }

  // Get IAM user keys right after component mounts.
  async componentDidMount() {
    Auth.currentSession()
      .then(async data => {
        var res = await fetch(
          `${process.env.GATSBY_API_URL}/secrets?archiveid=${this.props.archiveid}`,
          {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
              'Authorization': 'Bearer ' + data.idToken.jwtToken
            })
          }
        );

        const result = await res.json();

        await this.setState({
          secrets: result
        });

        this.disabled = false;
      })
      .catch(err => console.log(err));
  }

  async uploadSelectedFiles() {
    this.successfulUploadCounter = 0;
    this.failedUploadCounter = 0;
    this.uploadedFiles = [];
    this.failures = [];

    const { files } = this.state;

    if (files === null) {
      return;
    }

    this.spin = true;
    this.disabled = true;
    this.buttonText = 'Uploading your files ';
    this.buttonIcon = faSyncAlt;

    var filesCount = files.length;

    for (let i = 0; i < filesCount; i++) {
      const filename = files[i].name;
      const reader = new FileReader();
      const type = files[i].type;

      reader.onload = (reader) => {
        const data = reader.target.result;

        var s3_interface = new AWS.S3({
          apiVersion: '2006-03-01',
          accessKeyId: this.state.secrets.access_key_id,
          secretAccessKey: this.state.secrets.secret_access_key,
          region: 'eu-west-1'
        });

        var params = {
          Body: data,
          Bucket: `${process.env.GATSBY_S3_BUCKET}`,
          Key: `${this.props.archiveid}/${filename}`,
          ACL: 'public-read',
          ContentType: type
        };

        s3_interface.putObject(params, (err) => {
          if (err) {
            this.failures.push(filename);
            this.failedUploadCounter++;

            if ((this.failedUploadCounter + this.successfulUploadCounter) === filesCount) {
              this.spin = false;
              this.buttonText = 'Upload selected files ';
              this.disabled = false;
              this.buttonIcon = faCloudUploadAlt;
            }
          } else {
            this.successfulUploadCounter++;
            this.uploadedFiles.push(filename);

            if ((this.failedUploadCounter + this.successfulUploadCounter) === filesCount) {
              this.spin = false;
              this.buttonText = 'Upload selected files ';
              this.disabled = false;
              this.buttonIcon = faCloudUploadAlt;
            }
          }
        });
      }

      reader.readAsArrayBuffer(files[i]);
    }

  }

  render() {
    return(
      <React.Fragment>
        <input onChange={this.handleInputChange} type='file' multiple></input>
        <p />
        <button
          class='c-btn c-btn--success c-btn--medium'
          onClick={this.uploadSelectedFiles}
          disabled={this.disabled}
        >
          {this.buttonText}<FontAwesomeIcon icon={this.buttonIcon} size='1x' spin={this.spin} />
        </button>
        <p />
        { this.successfulUploadCounter > 0 &&
          <p>Successful uploads:</p>
        }
        <ul>
          {
            this.uploadedFiles.map((filename, index) => (
              <li key={index}>
                {filename}
              </li>
            ))
          }
        </ul>
        { this.failedUploadCounter > 0 &&
          (
            <React.Fragment>
              <p>Failed uploads: <Tooltip>
              A failed upload is likely due to a timeout, meaning that the file is too large to upload using your internet connection.
              </Tooltip></p>
            </React.Fragment>
          )
        }
        <ul>
          {
            this.failures.map((filename, index) => (
              <li key={index}>
                {filename}
              </li>
            ))
          }
        </ul>
      </React.Fragment>
    );
  }
}

export default Uploader
