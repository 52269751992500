import React from "react"
import { Auth } from "aws-amplify"
import { navigate } from "gatsby"

class DeleteArchiveButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmation: false
    };

    this.deleteArchive = this.deleteArchive.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    this.setState({
      confirmation: event.target.checked
    });
  }

  async deleteArchive() {
    await Auth.currentSession()
      .then(async data => {
        await fetch(`${process.env.GATSBY_API_URL}/vault/${this.props.archiveid}`,
          { method: 'DELETE',
            mode: 'cors',
            headers: new Headers({
              'Authorization': 'Bearer ' + data.idToken.jwtToken
            })}
        );
      })
      .catch(err => console.log(err));

    navigate("/");
  }

  render() {
    return(
      <React.Fragment>
        <div class="c-form__element">
          <label class="c-form__label" for="a">Delete this archive</label>
          <p />
	  <button class="c-btn c-btn--danger c-btn--medium" onClick={this.deleteArchive} disabled={!this.state.confirmation}>
	    Delete
	  </button>
          <div class="c-form__element-group" id="a">
            <div class="c-form__checkbox-group">
              <input id='comfirmation' class="c-form__checkbox" type="checkbox" checked={this.state.confirmation} onChange={this.handleInputChange} />
              <label for="comfirmation" class="c-form__label">
                I accept that, by deleting this archive, all its contents will be <b>permanently</b> deleted.
              </label>
            </div>
          </div>
        </div>
      </React.Fragment>);
  }
}

export default DeleteArchiveButton
